import React from 'react';
import './VocationalTrainingCenter.css';
import voca1 from "../components/img/voca1.jpg";
import voca2 from "../components/img/voca2.jpg";
import voca3 from "../components/img/voca3.jpeg";
import voca4 from "../components/img/voca4.avif";
import voca5 from "../components/img/voca5.jpg";
import voca6 from "../components/img/voca6.jpg";
import voca7 from "../components/img/voca7.jpeg";

const VocationalTrainingCenter = () => {
  return (
    <div className="vocational-training-center">
    <header className="header"><br />
        <img 
            src={voca1} 
            alt="Vocational Training Center" 
            className="header-image"
        />
        <h1>Vocational Training Center</h1>
        <p>Equipping blind and orphaned children with essential vocational skills to build independent futures.</p>
    </header>

    <section className="program-details">
        <h2>About the Program</h2>
        <p>
            Our Vocational Training Center is dedicated to empowering blind and orphaned children with practical skills that enable them to lead independent lives. We offer hands-on training in a variety of trades, helping them develop valuable skills and confidence for a better future.
        </p>
    </section>

    <section className="curriculum">
        <h2>Training Programs</h2>
        <div className="curriculum-content">
            {trainingPrograms.map((item, index) => (
                <div key={index} className="curriculum-item">
                    <img src={item.image} alt={item.title} />
                    <h3>{item.title}</h3>
                    <p>{item.description}</p>
                </div>
            ))}
        </div>
    </section>

    {/* <section className="success-stories">
        <h2>Success Stories</h2>
        <div className="story-content">
            {successStories.map((story, index) => (
                <div key={index} className="story-item">
                    <h3>{story.title}</h3>
                    <p>{story.description}</p>
                </div>
            ))}
        </div>
    </section> */}

    <section className="quote-section">
        <blockquote>
            “Training is the first step towards self-reliance and dignity.”
        </blockquote>
    </section><br /><br />
</div>
);
};

// Training Program Data
const trainingPrograms = [
{ title: "Tailoring and Sewing", description: "Teaching the art of tailoring to provide career opportunities.", image: voca2 },
{ title: "Handicrafts and Art", description: "Fostering creativity through handicrafts.", image: voca3 },
{ title: "Computer Skills", description: "Equipping children with basic computer skills for the digital age.", image: voca4 },
{ title: "Culinary Arts", description: "Training children in the culinary arts to open opportunities in food services.", image: voca5 },
{ title: "Basic Electrical Work", description: "Teaching basic electrical repair skills for potential employment.", image: voca6 },
{ title: "Bamboo Crafting", description: "Sustainable and eco-friendly crafting techniques.", image: voca7 },
];

// // Success Story Data
// const successStories = [
// { title: "Ajay's Journey", description: "Ajay learned tailoring and now runs a successful tailoring shop in his hometown." },
// { title: "Meena’s Progress", description: "Meena, after completing our computer training, secured a data entry job in a local company." },
// { title: "Ravi’s Culinary Success", description: "Ravi now works as a chef in a renowned restaurant after excelling in our culinary program." },
// ];

export default VocationalTrainingCenter;
