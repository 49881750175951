import React from 'react'
import "./PrimarytoClassEducation.css";
import primary2 from "../components/img/primary2.jpg"
import primary3 from "../components/img/primary3.jpg"
import primary4 from "../components/img/primary4.webp"
import primary5 from "../components/img/primary5.jpg"

const PrimarytoClassEducation = () => {
  return (
    <div className="primary10-container"><br />
      
    {/* 1. Header */}
    <header className="primary10-header">
      <div className="primary10-header-content">
        <h1>Primary to 10th Class Education for Blind Children</h1>
        <p>Empowering Through Inclusive Education</p>
      </div>
    </header>

    {/* 2. Introduction Section */}
    <section className="primary10-intro">
      <div className="primary10-intro-content">
        <div className="primary10-intro-text"><br />
          <h2>About Our Education Program</h2>
          <p className='text-start'>
          At Jagatguru Rambhdracharya Divyang Kalyan Sansthan (JRD), our education program is designed to provide blind children with a comprehensive and inclusive learning experience from primary through 10th grade. We are committed to delivering high-quality education that caters to the unique needs of each student, ensuring they receive the support necessary to thrive academically and personally.


          <br /> <br />
          Our program integrates specialized teaching methods and adaptive technologies to facilitate effective learning. We offer a curriculum that includes core academic subjects, life skills, and personal development, all tailored to the needs of blind students.    
          </p>
        </div>
        <div className="primary10-intro-image">
          <img src={primary2} alt="Visually impaired students learning" />
        </div>
      </div>
    </section>

    {/* 3. Curriculum Overview */}
    <section className="primary10-curriculum"><br />
      <h2>Curriculum Overview</h2><br />
      <div className="primary10-curriculum-grid">
        <div className="primary10-curriculum-item">
          <img src={primary3} alt="Braille Literacy" />
          <h3>Braille Literacy</h3>
          <p className='text-start'>At Jagatguru Rambhdracharya Divyang Kalyan Sansthan (JRD), we focus on teaching Braille literacy to empower blind students to read and write effectively. Our program provides essential Braille skills, enabling students to access educational materials and enhance their independence. Through tailored instruction, we ensure that each student gains proficiency in Braille, laying the foundation for academic success and personal growth.</p>
        </div>
        <div className="primary10-curriculum-item">
          <img src={primary4} alt="Mathematics" />
          <h3>Mathematics</h3>
          <p className='text-start'>At Jagatguru Rambhdracharya Divyang Kalyan Sansthan (JRD), we teach mathematics using tactile methods and audio tools. Our approach ensures that blind students can understand and engage with mathematical concepts through touch and sound, providing them with the skills needed for academic success and practical application.</p>
        </div>
        {/* Add more curriculum items as needed */}
      </div>
    </section>

    {/* 4. Success Stories */}
    <section className="primary10-success-stories">
      {/* <h2>Success Stories</h2> */}
      <div className="primary10-carousel">
        {/* Implement a slider for multiple testimonials */}
        <div className="primary10-testimonial">
          <p>"Primary to 10th Class Education for Blind Children"</p>
          {/* <span>- Student Name</span> */}
        </div>
        {/* Add more testimonials */}
      </div>
    </section>

    {/* 5. Our Approach */}
    <section className="primary10-approach">
      <div className="primary10-approach-content">
        <div className="primary10-approach-text">
          <h2>Our Approach</h2>
          <p className='text-start'>
          At Jagatguru Rambhdracharya Divyang Kalyan Sansthan (JRD), our approach to education is centered around inclusivity and accessibility. We utilize innovative methods and specialized tools to ensure that blind and orphaned children receive a comprehensive learning experience. By integrating tactile resources, audio aids, and personalized support, we create a nurturing environment where every child can thrive academically and personally. Our commitment to adaptive teaching ensures that each student can engage with their education effectively, develop essential skills, and build a foundation for future success.
          </p>
        </div>
        <div className="primary10-approach-image">
          <img src={primary5} alt="Inclusive education approach" />
        </div>
      </div>
    </section>

    {/* 6. Call to Action */}
    {/* <section className="primary10-cta">
      <h2>Get Involved</h2>
      <p>Support our mission by volunteering, donating, or spreading awareness.</p>
      <button className="primary10-cta-button">Donate Now</button>
    </section> */}

  </div>
  )
}

export default PrimarytoClassEducation
