import React from 'react';
import pdfFile from '../components/img/document.pdf'; // Adjust path as needed
import './Documents.css'; // Import the CSS file

const Documents = () => {
  const handleClick = () => {
    window.open(pdfFile, '_blank');
  };

  return (
    <div className="documents-container"><br /><br /><br />
      <h1>Documents</h1>
      <p>Click the button below to view the document:</p>
      <button onClick={handleClick}>Open PDF</button><br /><br /><br />
    </div>
  );
};

export default Documents;
