import React, { useState } from 'react';
import Logo from "../components/img/Logo.png";
import './Navbar.css';
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import About from './About';
import Home from './Home';
// import Testimonials from './Testimonials';
// import Videos from './Videos';
// import Blogs from './Blogs';
import ContactUs from './ContactUs';
// import InVitroFertilization from '../droppages/InVitroFertilization';
// import HighRiskPregnancy from '../droppages/HighRiskPregnancy';
// import OvulationInduction from '../droppages/OvulationInduction';
// import MaleInfertility from '../droppages/MaleInfertility';
// import FemaleInfertility from '../droppages/FemaleInfertility';
// import FertilityEnhancingSurgeries from '../droppages/FertilityEnhancingSurgeries';
// import IuiTreatment from '../droppages/IuiTreatment';
// import Cryopreservation from '../droppages/Cryopreservation';
// import ThirdPartyReproduction from '../droppages/ThirdPartyReproduction';
// import IvfFailures from '../droppages/IvfFailures';
// import FemaleInfertility2 from '../droppages/FemaleInfertility2';
// import Pcos from '../droppages/Pcos';
// import Endometriosis from '../droppages/Endometriosis';
// import OverianCyst from '../droppages/OverianCyst';
// import Miscarring from '../droppages/Miscarring';
// import TubeBlock from '../droppages/TubeBlock';
// import AdvancedAge from '../droppages/AdvancedAge';
// import LowOvarianReserve from '../droppages/LowOvarianReserve';
import ScrollToTop from './ScrollToTop'; // Import ScrollToTop component
import VisionMission from './VisionMission';
import ManagementCommittee from './ManagementCommittee';
import OurVolunteer from './OurVolunteer';
import Objectives from './Objectives';
import PrimarytoClassEducation from './PrimarytoClassEducation';
import CandleMaking from './CandleMaking';
import SewingTrainingCenter from './SewingTrainingCenter';
// import Gallery from './Gallery';
import HowYourCanHelp from './HowYourCanHelp';
import ImageGallery from './ImageGallery';
import VideoGallery from './VideoGallery';
import ComputerTraning from './ComputerTraning';
import HomeManagementCourses from './HomeManagementCourses';
import VocationalTrainingCenter from './VocationalTrainingCenter';
import Documents from './Documents';

const Navbar = () => {
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);

  const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);
  const handleNavItemClick = () => setIsNavCollapsed(true);

  return (
    <Router>
      <div>
        <ScrollToTop /> {/* Add the ScrollToTop component here */}
        <header>
          <nav className="navbar navbar-expand-lg navbar-light bg-light py-0">
            <div className="container" style={{ paddingTop: '5px', paddingBottom: '5px' }}>
              <Link className="navbar-brand" to="/" onClick={handleNavItemClick}>
                <span role="img" aria-label="Hello">
                  <img src={Logo} alt="" />
                </span>
              </Link>
              <button className="navbar-toggler" type="button" onClick={handleNavCollapse} aria-controls="navbarSupportedContent" aria-expanded={!isNavCollapsed} aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className={`${isNavCollapsed ? 'collapse' : ''} navbar-collapse`} id="navbarSupportedContent">
                <ul className="navbar-nav">
                  <li className="nav-item">
                    <Link className="nav-link active men-link ms-3" aria-current="page" to="/" onClick={handleNavItemClick} style={{color: '#603258'}}>Home</Link>
                  </li>
                  {/* <li className="nav-item">
                    <Link className="nav-link women-link ms-3" to="/about-us" onClick={handleNavItemClick} style={{color: '#603258'}}>About Us</Link>
                  </li> */}
                  <li className="nav-item nav ms-3">
                    <a className="nav-link nav-toggle" href="/" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false" style={{color: '#603258'}}>About<svg
                      className="dropdown-icon"
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="12"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="M6 9l6 6 6-6" />
                    </svg>
                    </a>
                    <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                      <li><Link className="dropdown-item" to="/vision-mission" onClick={handleNavItemClick} style={{color: '#603258'}}>Vision - Mission</Link></li>
                      <hr className="dropdown-divider" />
                      <li><Link className="dropdown-item" to="/management-committee" onClick={handleNavItemClick} style={{color: '#603258'}}>Management Committee</Link></li>
                      <hr className="dropdown-divider" />
                      <li><Link className="dropdown-item" to="/our-volunteer" onClick={handleNavItemClick} style={{color: '#603258'}}>Our Volunteer</Link></li>
                      <hr className="dropdown-divider" />
                      <li><Link className="dropdown-item" to="/objectives" onClick={handleNavItemClick} style={{color: '#603258'}}>Objectives</Link></li>
                      {/* <hr className="dropdown-divider" />
                      <li><Link className="dropdown-item" to="/femaleinfertility" onClick={handleNavItemClick} style={{color: '#603258'}}>Female Infertility</Link></li>
                      <hr className="dropdown-divider" />
                      <li><Link className="dropdown-item" to="/fertilityenhancingsurgeries" onClick={handleNavItemClick} style={{color: '#603258'}}>InFertility Enhancing Surgeries</Link></li>
                      <hr className="dropdown-divider" />
                      <li><Link className="dropdown-item" to="/intrauterineinseminationIUItreatment" onClick={handleNavItemClick} style={{color: '#603258'}}>Intrauterine Insemination (IUI) Treatment</Link></li>
                      <hr className="dropdown-divider" />
                      <li><Link className="dropdown-item" to="/fertilitycryopreservation" onClick={handleNavItemClick} style={{color: '#603258'}}>InFertility Cryopreservation</Link></li>
                      <hr className="dropdown-divider" />
                      <li><Link className="dropdown-item" to="/thirdpartyreproduction" onClick={handleNavItemClick} style={{color: '#603258'}}>Third Party Reproduction</Link></li>
                      <hr className="dropdown-divider" />
                      <li><Link className="dropdown-item" to="/ivffailures" onClick={handleNavItemClick} style={{color: '#603258'}}>IVF Failures</Link></li> */}
                    </ul>
                  </li>
                  <li className="nav-item nav ms-3">
                    <a className="nav-link nav-toggle" href="/" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false" style={{color: '#603258'}}>What We Do<svg
                      className="dropdown-icon"
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="12"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="M6 9l6 6 6-6" />
                    </svg>
                    </a>
                    <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                      <li><Link className="dropdown-item" to="/primary-to-10-class-education" onClick={handleNavItemClick} style={{color: '#603258'}}>Primary to 10 Class Education</Link></li>
                      <hr className="dropdown-divider" />
                      <li><Link className="dropdown-item" to="/computer-traning" onClick={handleNavItemClick} style={{color: '#603258'}}>Computer Traning Center</Link></li>
                      <hr className="dropdown-divider" />
                      <li><Link className="dropdown-item" to="/home-management-courses" onClick={handleNavItemClick} style={{color: '#603258'}}>Home Management Courses</Link></li>
                      <hr className="dropdown-divider" />
                      <li><Link className="dropdown-item" to="/vocational-traning-center" onClick={handleNavItemClick} style={{color: '#603258'}}> Vocational Training Center</Link></li>
                      {/* <hr className="dropdown-divider" /> */}
                     
                      {/* <li><Link className="dropdown-item" to="/candle-making" onClick={handleNavItemClick} style={{color: '#603258'}}>Candle Making</Link></li>
                      <hr className="dropdown-divider" />
                      <li><Link className="dropdown-item" to="/sewing-traning-center" onClick={handleNavItemClick} style={{color: '#603258'}}>Sewing Training Center</Link></li> */}
                      {/* <hr className="dropdown-divider" />
                      <li><Link className="dropdown-item" to="/ovariancyst" onClick={handleNavItemClick} style={{color: '#603258'}}>Ovarian Cyst</Link></li>
                      <hr className="dropdown-divider" />
                      <li><Link className="dropdown-item" to="/recurrentpregnancyloss(miscarriage)" onClick={handleNavItemClick} style={{color: '#603258'}}>Recurrent Pregnancy Loss (Miscarriage)</Link></li>
                      <hr className="dropdown-divider" />
                      <li><Link className="dropdown-item" to="/fallopiantubeblock/tubolblock" onClick={handleNavItemClick} style={{color: '#603258'}}>Fallopian Tube Block/Tubol Block</Link></li>
                      <hr className="dropdown-divider" />
                      <li><Link className="dropdown-item" to="/advancedage" onClick={handleNavItemClick} style={{color: '#603258'}}>Advanced Age</Link></li>
                      <hr className="dropdown-divider" />
                      <li><Link className="dropdown-item" to="/lowovarianreserve" onClick={handleNavItemClick} style={{color: '#603258'}}>Low Ovarian Reserve</Link></li> */}
                    </ul>
                  </li>
                  <li className="nav-item nav ms-3">
                    <a className="nav-link nav-toggle" href="/" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false" style={{color: '#603258'}}>Gallery<svg
                      className="dropdown-icon"
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="12"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="M6 9l6 6 6-6" />
                    </svg>
                    </a>
                    <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                      <li><Link className="dropdown-item" to="/image-gallery" onClick={handleNavItemClick} style={{color: '#603258'}}>Image - Gallery</Link></li>
                      <hr className="dropdown-divider" />
                      <li><Link className="dropdown-item" to="/video-gallery" onClick={handleNavItemClick} style={{color: '#603258'}}>Video - Gallery</Link></li>
                      {/* <hr className="dropdown-divider" /> */}
                      {/* <li><Link className="dropdown-item" to="/our-volunteer" onClick={handleNavItemClick} style={{color: '#603258'}}>Our Volunteer</Link></li>
                      <hr className="dropdown-divider" />
                      <li><Link className="dropdown-item" to="/objectives" onClick={handleNavItemClick} style={{color: '#603258'}}>Objectives</Link></li> */}
                      {/* <hr className="dropdown-divider" />
                      <li><Link className="dropdown-item" to="/femaleinfertility" onClick={handleNavItemClick} style={{color: '#603258'}}>Female Infertility</Link></li>
                      <hr className="dropdown-divider" />
                      <li><Link className="dropdown-item" to="/fertilityenhancingsurgeries" onClick={handleNavItemClick} style={{color: '#603258'}}>InFertility Enhancing Surgeries</Link></li>
                      <hr className="dropdown-divider" />
                      <li><Link className="dropdown-item" to="/intrauterineinseminationIUItreatment" onClick={handleNavItemClick} style={{color: '#603258'}}>Intrauterine Insemination (IUI) Treatment</Link></li>
                      <hr className="dropdown-divider" />
                      <li><Link className="dropdown-item" to="/fertilitycryopreservation" onClick={handleNavItemClick} style={{color: '#603258'}}>InFertility Cryopreservation</Link></li>
                      <hr className="dropdown-divider" />
                      <li><Link className="dropdown-item" to="/thirdpartyreproduction" onClick={handleNavItemClick} style={{color: '#603258'}}>Third Party Reproduction</Link></li>
                      <hr className="dropdown-divider" />
                      <li><Link className="dropdown-item" to="/ivffailures" onClick={handleNavItemClick} style={{color: '#603258'}}>IVF Failures</Link></li> */}
                    </ul>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link women-link ms-3" to="/how-you-can-help" onClick={handleNavItemClick} style={{color: '#603258'}}>How You Can Help</Link>
                  </li>
                  {/* <li className="nav-item">
                    <Link className="nav-link women-link ms-3" to="/blogs" onClick={handleNavItemClick} style={{color: '#603258'}}>Blogs</Link>
                  </li> */}
                  <li className="nav-item">
                    <Link className="nav-link women-link ms-3" to="/contact-us" onClick={handleNavItemClick} style={{color: '#603258'}}>Contact Us</Link>
                  </li>
                  <button className="call-button">
                    <a rel="nofollow" className="component-target text-white" href="tel:+(91)-09457836980">
                      <i className="fa fa-phone"></i>
                      <span className="component-target-text">+(91)-09457836980</span>
                    </a>
                  </button>
                </ul>
              </div>
            </div>
          </nav>
        </header>
        <div className="main-content"> {/* Added this wrapper div */}
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/" element={<About />} />
            <Route path="/vision-mission" element={<VisionMission />} />
            <Route path="/management-committee" element={<ManagementCommittee />} />

            
            <Route path="/our-volunteer" element={<OurVolunteer />} />
            <Route path="/objectives" element={<Objectives />} />
            <Route path="/primary-to-10-class-education" element={<PrimarytoClassEducation />} />
            <Route path="/computer-traning" element={<ComputerTraning />} />
            <Route path="/home-management-courses" element={<HomeManagementCourses />} />
            <Route path="/vocational-traning-center" element={<VocationalTrainingCenter />} />

            Documents
            <Route path="/candle-making" element={<CandleMaking />} />
            <Route path="/sewing-traning-center" element={<SewingTrainingCenter/>} />
            <Route path="/image-gallery" element={<ImageGallery/>} />
            <Route path="/video-gallery" element={<VideoGallery/>} />

           
            {/* <Route path="/fertilityenhancingsurgeries" element={<FertilityEnhancingSurgeries />} />
            <Route path="/intrauterineinseminationIUItreatment" element={<IuiTreatment />} />
            <Route path="/fertilitycryopreservation" element={<Cryopreservation />} />
            <Route path="/thirdpartyreproduction" element={<ThirdPartyReproduction />} />
            <Route path="/ivffailures" element={<IvfFailures />} />
            <Route path="/female-infertility" element={<FemaleInfertility2 />} />
            <Route path="/pcos" element={<Pcos />} />
            <Route path="/endometriosis/adenomyosis" element={<Endometriosis />} />
            <Route path="/ovariancyst" element={<OverianCyst />} />
            <Route path="/recurrentpregnancyloss(miscarriage)" element={<Miscarring />} />
            <Route path="/fallopiantubeblock/tubolblock" element={<TubeBlock />} />
            <Route path="/advancedage" element={<AdvancedAge />} /> */}
            {/* <Route path="/gallery" element={<Gallery />} /> */}
            <Route path="/how-you-can-help" element={<HowYourCanHelp/>} />
            {/* <Route path="/videos" element={<Videos />} />
            <Route path="/blogs" element={<Blogs />} /> */}
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/documents" element={<Documents />} />

          </Routes>
        </div>
      </div>
    </Router>
  );
};

export default Navbar;
