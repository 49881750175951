import React, { useState } from 'react';
import './ContactForm.css';
import { useForm } from 'react-hook-form';
import { useInView } from 'react-intersection-observer';

function ContactForm() {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [submitted, setSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { ref: formRef, inView: formInView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });
  const { ref: mapRef, inView: mapInView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const onSubmit = async (data) => {
    setIsSubmitting(true);
    try {
      const response = await fetch('https://formspree.io/f/mkgwbdow', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      setSubmitted(true);
    } catch (error) {
      console.error('Error submitting form:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="contact-form-map-container">
      
      <div ref={mapRef} className={`map-container ${mapInView ? 'animate' : ''}`}>
      <iframe
  src="https://www.google.com/maps/embed?pb=!1m"
  width="100%"
  height="100%"
  allowFullScreen=""
  loading="lazy"
  title="Google Maps Location"
></iframe>

      </div>
      <div ref={formRef} className={`contact-form-container ${formInView ? 'animate' : ''}`}>
        <h1 className='text-start text-white'><b>Query Form</b></h1><br />
        {submitted ? (
          <p className="success">Thank you for your message. We will get back to you soon.</p>
        ) : (
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-group">
              <div>
                <label htmlFor="name">Full Name</label>
                <input type="text" id="name" {...register("name", { required: true })} aria-invalid={errors.name ? "true" : "false"} />
                {errors.name && <p className="error">Name is required</p>}
              </div>
              <div>
                <label htmlFor="email">Email</label>
                <input type="email" id="email" {...register("email", { required: true, pattern: /^\S+@\S+$/i })} aria-invalid={errors.email ? "true" : "false"} />
                {errors.email && <p className="error">Please enter a valid email address</p>}
              </div>
              <div>
                <label htmlFor="phone">Mob.</label>
                <input type="tel" id="phone" {...register("phone", { required: true })} aria-invalid={errors.phone ? "true" : "false"} />
                {errors.phone && <p className="error">Phone number is required</p>}
              </div>
              <div>
                <label htmlFor="date">Date</label>
                <input type="date" id="date" {...register("date", { required: true })} aria-invalid={errors.date ? "true" : "false"} />
                {errors.date && <p className="error">Date is required</p>}
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="message">Message</label>
              <textarea id="message" {...register("message", { required: true })} aria-invalid={errors.message ? "true" : "false"}></textarea>
              {errors.message && <p className="error">Message is required</p>}
            </div>
            <button type="submit" disabled={isSubmitting}>
              {isSubmitting ? <span className="spinner"></span> : 'Submit'}
            </button>
          </form>
        )}
      </div>
    </div>
  );
}

export default ContactForm;
