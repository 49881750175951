import React, { useState } from 'react';
import "./HowYourCanHelp.css";
const HowYourCanHelp = () => {
    const [isHoveredEmail, setIsHoveredEmail] = useState(false);
  const [isHoveredPhone, setIsHoveredPhone] = useState(false);
  return (
    <div className="how-you-can-help"><br /><br />
    {/* Introduction Section */}
    <section className="intro">
        <h1>Join Us in Making a Difference</h1>
        <p>Your support can transform lives. There are numerous ways you can get involved and help us in our mission to provide a better future for those in need. Whether you contribute financially, volunteer your time, or spread the word, every effort counts.</p>
    </section>

    {/* Ways to Help Section */}
    <section className="ways-to-help">
        <div className="way">
            <h2>Make a Donation</h2>
            <p>Your generous donation can provide essential resources, education, and support to those who need it the most. Every contribution, big or small, makes a significant impact.</p>
            <a href="/" className="button">Donate Now</a>
        </div>

        <div className="way">
            <h2>Volunteer Your Time</h2>
            <p>Join our team of dedicated volunteers and help us deliver on-ground support, organize events, and engage with the community. Your time and skills can make a world of difference.</p>
            <a href="/our-volunteer" className="button">Become a Volunteer</a>
        </div>

        <div className="way">
            <h2>Raise Awareness</h2>
            <p>Help us reach more people by sharing our mission and work within your network. The more people know, the more we can achieve together.</p>
            <div className="social-buttons">
                {/* Social Media Buttons */}
            </div>
        </div>

        <div className="way">
            <h2>Partner with Us</h2>
            <p>If you represent a business or organization, consider partnering with us. Corporate sponsorship can provide both financial support and opportunities for collaborative projects.</p>
            <a href="/" className="button">Learn More</a>
        </div>
    </section>

    {/* Impact Section */}
    <section className="impact">
        <h2>Your Impact</h2>
        <p>See how your contributions have made a tangible difference in the lives of those we serve. Your involvement helps us achieve our goals and create lasting change.</p>
        {/* Impact Stories or Statistics */}
    </section>

    {/* Contact Section */}
    <section className="contact">
        <h2>Get in Touch</h2>
        <p>If you have any questions or would like to discuss how you can help further, feel free to reach out to us.</p>
        <div className="contact-info">
        <a
        href="mailto:jrdkalyansansthan@gmail.com"
        style={{
          color: isHoveredEmail ? "#fd7e14" : "black", // Change color on hover
          textDecoration: "none",  // No underline
        }}
        onMouseEnter={() => setIsHoveredEmail(true)}  // Trigger color change on hover
        onMouseLeave={() => setIsHoveredEmail(false)} // Reset color when hover ends
      >
        <i className="fas fa-envelope"></i> jrdkalyansansthan@gmail.com
      </a>
      <br /> <br />

      {/* Phone Link */}
      <a
        rel="nofollow"
        className="component-target"
        href="tel:+(91)-09457836980"
        style={{
          color: isHoveredPhone ? "#fd7e14" : "black",  // Change color on hover
          textDecoration: "none",  // No underline
        }}
        onMouseEnter={() => setIsHoveredPhone(true)}  // Trigger color change on hover
        onMouseLeave={() => setIsHoveredPhone(false)} // Reset color when hover ends
      >
        <i className="fa fa-phone"></i>
        <span className="component-target-text"> +(91)-09457836980</span>
      </a>

            {/* Contact Form */}
        </div>
    </section>
</div>
  )
}

export default HowYourCanHelp
