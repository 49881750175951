import React from 'react'
import CommitteeMembers from './CommitteeMembers'

const ManagementCommittee = () => {
  return (
    <div><br />
      <CommitteeMembers/>
    </div>
  )
}

export default ManagementCommittee
