import React from 'react';
import './HomeManagementCourses.css';
import home1 from "../components/img/home1.jpg";
import home2 from "../components/img/home2.jpg";
import home3 from "../components/img/home3.avif";
import home4 from "../components/img/home4.jpg";
import home5 from "../components/img/home5.jpg";
import home6 from "../components/img/home6.png";
import home7 from "../components/img/home7.jpg";

const HomeManagementCourses = () => {
    return (
        <div className="home-management"><br />
            <header className="header">
                <img  src={home1}  alt="Home Management Courses" className="header-image"/>
                <h1>Home Management Courses</h1>
                <p>Empowering children and young adults with essential life skills.</p>
            </header>

            <section className="program-details">
                <h2>About the Program</h2>
                <p>
                    Our Home Management Courses teach essential life skills that empower children and young adults, particularly those who are visually impaired or orphaned, to lead independent lives. This program offers practical training in areas such as home safety, cooking, and financial management, which are vital for everyday living.
                </p>
            </section>

            <section className="curriculum">
                <h2>Curriculum Highlights</h2>
                <div className="curriculum-content">
                    {curriculumItems.map((item, index) => (
                        <div key={index} className="curriculum-item">
                            <img src={item.image} alt={item.title} />
                            <h3>{item.title}</h3>
                            <p>{item.description}</p>
                        </div>
                    ))}
                </div>
            </section>

            <section className="quote-section">
                <blockquote>
                    “Empowering individuals with the skills to manage their lives independently is the greatest gift.”
                </blockquote>
            </section>
        </div>
    );
};

// Curriculum Data
const curriculumItems = [
    { title: "Basic Cooking and Nutrition", description: "Prepare nutritious meals and understand food safety.", image: home2 },
    { title: "Cleaning and Organization", description: "Maintain a clean and organized living space.", image: home3 },
    { title: "Budgeting and Financial Management", description: "Learn to manage finances and create a budget.", image: home4 },
    { title: "Personal Hygiene and Self-care", description: "Focus on personal hygiene and well-being.", image: home5 },
    { title: "Time Management", description: "Effectively manage time for better productivity.", image: home6 },
    { title: "Basic First Aid and Home Safety", description: "Learn essential first-aid and home safety practices.", image: home7 }
];

export default HomeManagementCourses;
