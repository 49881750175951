import React, { useEffect } from 'react';
import './VideoPage.css';

const VideoPage = () => {
  useEffect(() => {
    const videos = document.querySelectorAll('.video');

    const scrollHandler = () => {
      videos.forEach(video => {
        const videoTop = video.getBoundingClientRect().top;
        const windowHeight = window.innerHeight;

        if (videoTop < windowHeight * 0.75) {
          video.classList.add('animate');
        }
      });
    };

    window.addEventListener('scroll', scrollHandler);

    // Clean up event listener when component unmounts
    return () => {
      window.removeEventListener('scroll', scrollHandler);
    };
  }, []);

  return (
    <div className="video-page">
    <div className="video-container">
    <iframe
  className="video"
  id="video-1"
  width="360"
  height="215"
  src="https://www.youtube.com/embed/glWpcNxQBCE"
  title="YouTube video player"
  frameBorder="0"
  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
  allowFullScreen
></iframe>


<iframe
  className="video"
  id="video-1"
  width="360"
  height="215"
  src="https://www.youtube.com/embed/8paDsCQLroU"
  title="YouTube video player"
  frameBorder="0"
  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
  allowFullScreen
></iframe>


<iframe
  className="video"
  id="video-1"
  width="360"
  height="215"
  src="https://www.youtube.com/embed/sbGw9m9igDA"
  title="YouTube video player"
  frameBorder="0"
  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
  allowFullScreen
></iframe>


<iframe
  className="video"
  id="video-1"
  width="360"
  height="215"
  src="https://www.youtube.com/embed/O3q80izIEEQ"
  title="YouTube video player"
  frameBorder="0"
  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
  allowFullScreen
></iframe>


<iframe
  className="video"
  id="video-1"
  width="360"
  height="215"
  src="https://www.youtube.com/embed/8E8FHpxacCo"
  title="YouTube video player"
  frameBorder="0"
  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
  allowFullScreen
></iframe>


<iframe
  className="video"
  id="video-1"
  width="360"
  height="215"
  src="https://www.youtube.com/embed/hcp7Gbkn1ik"
  title="YouTube video player"
  frameBorder="0"
  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
  allowFullScreen
></iframe>


{/* <iframe
  className="video"
  id="video-1"
  width="360"
  height="215"
  src="https://www.youtube.com/embed/_aktqa"
  title="YouTube video player"
  frameBorder="0"
  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
  allowFullScreen
></iframe>



<iframe
  className="video"
  id="video-1"
  width="360"
  height="215"
  src="https://www.youtube.com/embed/_aktqa"
  title="YouTube video player"
  frameBorder="0"
  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
  allowFullScreen
></iframe>

<iframe
  className="video"
  id="video-1"
  width="360"
  height="215"
  src="https://www.youtube.com/embed/_aktqa"
  title="YouTube video player"
  frameBorder="0"
  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
  allowFullScreen
></iframe>

<iframe
  className="video"
  id="video-1"
  width="360"
  height="215"
  src="https://www.youtube.com/embed/_aktqa"
  title="YouTube video player"
  frameBorder="0"
  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
  allowFullScreen
></iframe>

<iframe
  className="video"
  id="video-1"
  width="360"
  height="215"
  src="https://www.youtube.com/embed/_aktqa"
  title="YouTube video player"
  frameBorder="0"
  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
  allowFullScreen
></iframe>
<iframe
  className="video"
  id="video-1"
  width="360"
  height="215"
  src="https://www.youtube.com/embed/_aktqa"
  title="YouTube video player"
  frameBorder="0"
  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
  allowFullScreen
></iframe> */}
      </div>
      
    </div>
    
  );
};

export default VideoPage;
