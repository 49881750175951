import React from 'react';
import { useSpring, animated } from '@react-spring/web';
import manishImage from '../components/img/manishImage.png';
// Uncomment and replace these with correct paths when images are available
// import ebhaImage from '../components/img/ebha-garg.jpg';
// import ankitImage from '../components/img/ankit-khandelwal.jpg';
// import dheerajImage from '../components/img/dheeraj-arora.jpg';

const members = [
  {
    name: 'Manish Rai' ,
    role: 'Secretary',
    phone: '9808444600',
    image: manishImage,
    about: 'loremehdudgbbbbbbbbbbbbbbbbbbbbbbbbb',
  },
  {
    name: 'Ebha Garg',
    role: 'President',
    phone: '7500176976',
    image: manishImage,
  },
  {
    name: 'Ankit Khandelwal',
    role: 'Vice President',
    phone: '9760004809',
    image: manishImage,
  },
  {
    name: 'Dheeraj Arora',
    role: 'Treasurer',
    phone: '9808335533',
    image: manishImage,
  },
  {
    name: 'Manish Rai',
    role: 'Secretary',
    phone: '9808444600',
    image: manishImage,
  },
  {
    name: 'Ebha Garg',
    role: 'President',
    phone: '7500176976',
    image: manishImage,
  },
  {
    name: 'Ankit Khandelwal',
    role: 'Vice President',
    phone: '9760004809',
    image: manishImage,
  },
  {
    name: 'Dheeraj Arora',
    role: 'Treasurer',
    phone: '9808335533',
    image: manishImage,
  },
];

const CommitteeMembers = () => {
  return (
    <div style={styles.container}>
      {members.map((member, index) => (
        <MemberCard key={index} member={member} />
      ))}
    </div>
  );
};

const MemberCard = ({ member }) => {
  const [props, api] = useSpring(() => ({
    opacity: 0,
    transform: 'translateY(20px)',
  }));

  React.useEffect(() => {
    api.start({ opacity: 1, transform: 'translateY(0px)' });
  }, [api]);

  return (
    <animated.div style={{ ...styles.memberCard, ...props }}>
      {member.image && <img src={member.image} alt={member.name} style={styles.image} />}
      <div style={styles.info}>
        <h3 style={styles.name}>{member.name}</h3>
        <p style={styles.role}><i className="fa fa-user" aria-hidden="true"></i> {member.role}</p>
        <p style={styles.phone}><i className="fa fa-phone" aria-hidden="true"></i> {member.phone}</p>
        <animated.button
          style={{ ...styles.button, ...props }}
          onClick={() => handleKnowMore(member)}
        >
          Know More
        </animated.button>
      </div>
    </animated.div>
  );
};

const handleKnowMore = (member) => {
  // Implement the navigation to the detailed page of the member
  // Example: navigate(`/members/${member.name.replace(/\s+/g, '-').toLowerCase()}`);
  alert(`More information about ${member.name}`);
};

const styles = {
  container: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))',
    gap: '20px',
    padding: '20px',
    backgroundColor: '#f5f5f5',
  },
  memberCard: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#fff',
    borderRadius: '10px',
    overflow: 'hidden',
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
    transition: 'transform 0.3s, box-shadow 0.3s',
  },
  image: {
    width: '150px',
    height: '150px',
    objectFit: 'cover',
    borderRadius: '50%',
    marginTop: '20px',
  },
  
  info: {
    padding: '20px',
    textAlign: 'center',
  },
  name: {
    fontSize: '1.5em',
    color: '#541936',
    margin: '10px 0',
  },
  role: {
    fontSize: '1.2em',
    color: '#777',
    margin: '5px 0',
  },
  phone: {
    fontSize: '1em',
    color: '#555',
    margin: '5px 0',
  },
  button: {
    marginTop: '20px',
    padding: '10px 20px',
    backgroundColor: '#541936',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
  },
};

export default CommitteeMembers;
