import React from 'react';
import './AboutBanner.css';
import { motion } from 'framer-motion';
import { Link } from "react-router-dom";



const AboutBanner = () => {
  return (
    <div className="banner-container">
  <div>
      <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel">
        {/* <div className="carousel-indicators">
          <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
          <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
          <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
        </div> */}
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img src="" className="d-block w-100 carousel-image" alt="..." />
            {/* <div className="carousel-caption d-none d-md-block">
              <h5>First slide label</h5>
              <p>Some representative placeholder content for the first slide.</p>
            </div> */}
          </div>
          {/* <div className="carousel-item">
            <img src="https://www.drkamleshtandonhospital.com/images/banner1.jpg" className="d-block w-100" alt="..." />
            <div className="carousel-caption d-none d-md-block">
              <h5>Second slide label</h5>
              <p>Some representative placeholder content for the second slide.</p>
            </div>
          </div> */}
        </div>
        {/* <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Previous</span>
        </button> */}
        {/* <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Next</span>
        </button> */}
      </div>
    </div>
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <motion.img 
              src="" 
              className="img-fluid" 
              alt="Your Image" 
              initial={{ x: '-100vw' }}
              animate={{ x: 0 }}
              transition={{ type: 'spring', stiffness: 50 }}
            />
          </div>
          <div className="col-md-6">
            <motion.div 
              className="content"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.5, duration: 1.5 }}
            ><br />
              <h1 className='text-start ms-2'>Dr. Vaishali Tandon</h1>
              <div className="yellow-line ms-2"></div> {/* Yellow underline */}
                <br />
              <p className='text-start ms-2'>Welcome to the Clinic of Dr. Vaishali Tandon: your leading, advanced infertility care. Conceptualized by Dr. Vaishali Tandon herself, this clinic offers the latest treatments combined with individual care. Located at the core of Agra, we are one clinic that uses the best possible technology to ensure delivery.</p>
              <p className='text-start ms-2'>Gynaecologists and Obstetricians are medical practitioners with specialisations in both gynaecology and obstetrics. These doctors assist pregnant mothers pre and post-childbirth and help identify and manage illnesses and problems affecting the vagina, ovaries, uterus, and breasts. You can find the best Gynaecologist &amp; Obstetrician Doctors in Agra, if you are experiencing any of the aforementioned medical conditions.</p>
              <p className='text-start ms-2'>If you are an expecting mother or simply someone suffering from vaginal, hormonal or breast issues, we strongly suggest you visit Dr. Vaishali Tandon (Dr. Kamlesh Tandon Multi Speciality Hospital) in Laipat Kunj, Agra. They provide various services such as of Ovarian Tumors , Painless Normal Delivery , Diseases In Pregnancy.</p>
              <p className='text-start ms-2'>Are you an expecting mother or dealing with vaginal, hormonal, or breast issues? Visit Dr. Vaishali Tandon at Dr. Kamlesh Tandon Multi-Speciality Hospital in Laipat Kunj, Agra, for expert care.</p>
              <Link to="/contact-us" className="btn btn-warning ms-2 link-button">Contact Us</Link>
              {/* Add more content here */}
            </motion.div>
          </div>
        </div>
  

        <div className="container-A py-5">
  <div className="row">
    <div className="col-lg-4 col-md-6 mb-4">
      <div className="card-A">
        <img src="" className="card-img-top" alt="" />
        <div className="card-body">
          <h4 className='text-center'><b> Customized InFertility Solutions</b></h4>
          <p className='text-start ms-0 '><b>Get compassionate and individualized care in your infertility matters from our devoted infertility care team. We are here to provide customized solutions with continuous support on your journey.</b></p>
        </div>
      </div>
    </div>
    <div className="col-lg-4 col-md-6 mb-4">
      <div className="card-A">
        <img src="" className="card-img-top" alt="" />
        <div className="card-body">
          <h4 className='text-center' ><b>Expert Team, Successful Treatment</b></h4>
          <p className='text-start'><b>Welcome to our team of professionals who work without rest for the realization of your infertility success. We are here with expertise and unity to make your parenthood journey a reality, tailored specifically to your needs. <br /></b></p>
        </div>
      </div>
    </div>
    <div className="col-lg-4 col-md-1 mb-4">
      <div className="card-A">
        <img src="" className="card-img-top" alt="Dr. Shweta Goswami" />
        <div className="card-body">
          <h4 className='text-center'><b>Cutting-Edge Facilities</b></h4>
          <p className='text-start'><b>Experience our famous facilities that are furnished with the latest technologies and infrastructures. We are dedicated to utilize advanced methodologies which would help to increase the success rate of your infertility.</b></p>
        </div>
      </div>
    </div>
  </div>
</div>
      </div>
    </div>
  );
}

export default AboutBanner;
