import React from 'react'
import Banner from './Banner'


import FertilitySection from './FertilitySection';
// import DoctorsSection from './DoctorsSection';


// import Center from './Center';
// import HomeStat from './HomeStat';
// import HomeReview from './HomeReview';
import CenterContent from './CenterContent';
// import Carousel from './Carousel';






const Home = () => {
  return (
    <div>
       <br />
        <Banner/> <br />
        {/* <Carousel/> */}
        {/* <HomeReview/> */}
        <CenterContent/>
        <FertilitySection/>
        {/* <DoctorsSection/>     */}
       
        <br /><br />
        <hr />
       {/* <Center/> */}
       {/* <HomeStat/> */}
      
         
       
      
    </div>
  )
}

export default Home
