import React from 'react'
import { Link } from "react-router-dom";
import TeamMember from './TeamMember';

const CenterContent = () => {
  return (
    <div><br /><br />
      <h2>Jagatguru <br /> Rambhdracharya Divyang kalyan Sansthan (JRD)</h2><br />
      <p className='text-start text-center'>Jagatguru Rambhdracharya Divyang Kalyan Sansthan (JRD) is a dedicated Indian Non-Governmental <br /> Organisation established to uplift and empower underprivileged children and women across India. Founded on 2nd December 2009,  <br />JRD operates under the Societies Registration Act, 1860. Our organization was created by a group of passionate <br /> individuals from Agra who are committed to making a meaningful difference in the lives of those in need. <br /> Through our various programs and initiatives, we strive to provide support, education, <br /> and opportunities to help transform lives and build a brighter future for the less fortunate.</p>
      <Link to="/" className="btn btn-warning ms-2 link-button">Know More / Our Inspiration</Link><br />
      <TeamMember/>
    </div>
    
  )
}

export default CenterContent
