import React from 'react';
import './ComputerTraining.css';
import computer1 from "../components/img/computer1.avif"
const ComputerTraining = () => {
    return (
        <div className="computer-training">
            <header className="header"><br />
                <img src={computer1} alt="Computer Training" className="header-image" />
                <h1>Computer Training Program</h1>
                <p>Empowering children with essential computer skills for a brighter future.</p>
            </header>
            <section className="program-details">
                <h2>About the Program</h2>
                <p>Our Computer Training Program is designed to provide children with the fundamental skills needed to navigate the digital world confidently. Through interactive lessons and hands-on exercises, we equip them with practical knowledge in various essential applications.</p>
            </section>
            <section className="curriculum">
                <h2>Curriculum Highlights</h2>
                <div className="curriculum-content">
                    <div className="curriculum-item">
                        <h3>Introduction to Computers</h3>
                        <p>Learn the basics of computer hardware and software, including how to operate and maintain a computer.</p>
                    </div>
                    <div className="curriculum-item">
                        <h3>Microsoft Word</h3>
                        <p>Master the art of creating and formatting documents, including using various text styles, images, and tables.</p>
                    </div>
                    <div className="curriculum-item">
                        <h3>Microsoft Excel</h3>
                        <p>Gain proficiency in using spreadsheets for data organization, calculations, and creating charts.</p>
                    </div>
                    <div className="curriculum-item">
                        <h3>Microsoft PowerPoint</h3>
                        <p>Learn how to design engaging presentations with multimedia elements, animations, and effective slide layouts.</p>
                    </div>
                    <div className="curriculum-item">
                        <h3>Internet Basics</h3>
                        <p>Understand how to browse the internet safely, conduct research, and use online tools effectively.</p>
                    </div>
                    <div className="curriculum-item">
                        <h3>Typing Skills</h3>
                        <p>Improve typing speed and accuracy through targeted exercises and practice sessions.</p>
                    </div>
                    <div className="curriculum-item">
                        <h3>Operating System Basics</h3>
                        <p>Learn to use the operating system efficiently, manage files, and configure settings.</p>
                    </div>
                    <div className="curriculum-item">
                        <h3>File Management</h3>
                        <p>Master the art of creating, organizing, and managing files and folders, both locally and on the cloud.</p>
                    </div>
                    <div className="curriculum-item">
                        <h3>Basic Troubleshooting</h3>
                        <p>Understand how to troubleshoot common computer issues and perform basic hardware checks.</p>
                    </div>
                    <div className="curriculum-item">
                        <h3>Social Media Basics</h3>
                        <p>Learn how to safely create and manage social media accounts for personal and educational use.</p>
                    </div>
                    <div className="curriculum-item">
                        <h3>Cybersecurity Essentials</h3>
                        <p>Understand the importance of online safety, including strong passwords and protecting personal information.</p>
                    </div>
                    <div className="curriculum-item">
                        <h3>Google Workspace & Docs</h3>
                        <p>Gain proficiency in creating documents, spreadsheets, and presentations using Google Docs, Sheets, and Slides.</p>
                    </div>
                    <div className="curriculum-item">
                        <h3>Basic Printing</h3>
                        <p>Learn how to connect to a printer and print documents efficiently, and understand how to save files as PDFs.</p>
                    </div>
                    <div className="curriculum-item">
                        <h3>Multimedia Editing</h3>
                        <p>Introduction to simple photo and video editing tools for enhancing digital projects.</p>
                    </div>
                    <div className="curriculum-item">
                        <h3>Digital Communication Tools</h3>
                        <p>Master the basics of using tools like Zoom, Skype, and Google Meet for online communication.</p>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default ComputerTraining;
