import React from 'react'
import "./CandleMaking.css";
const CandleMaking = () => {
  return (
    <div><br />
  <div className="candle-wrapper">
      
      {/* 1. Hero Section */}
      <section className="candle-hero">
        <div className="candle-hero-text">
          <h1>Candle Making Workshop</h1>
          <p>Crafting Light, Shaping Dreams</p>
        </div>
      </section>

      {/* 2. Why Candle Making */}
      <section className="candle-why">
        <div className="candle-why-content">
          <h2>Why Candle Making?</h2>
          <p>
            Candle making is more than just a craft. It teaches blind children patience, 
            creativity, and practical skills that help them gain confidence and independence.
          </p>
        </div>
        <div className="candle-why-image">
          <img src="https://www.marthastewart.com/thmb/b8FCm8vj2yCSdHq1A6vTLaXON_U=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc()/candles-getty-0623-c4d26ce06f0045ea810fdbb6833eb4ea.jpg" alt="Children engaged in candle making" />
        </div>
      </section>

      {/* 3. Workshop Highlights */}
      <section className="candle-highlights">
        <div className="candle-highlights-title">
          <h2>Workshop Highlights</h2>
        </div>
        <div className="candle-highlights-grid">
          <div className="candle-highlight-item">
            {/* <img src="/path-to-image1.jpg" alt="Learning by Doing" className="highlight-image" /> */}
            <h3>Learning by Doing</h3>
            <p>Hands-on experience in crafting candles from scratch.</p>
          </div>
          <div className="candle-highlight-item">
            {/* <img src="/path-to-image2.jpg" alt="Sensory Engagement" className="highlight-image" /> */}
            <h3>Sensory Engagement</h3>
            <p>Incorporating touch, smell, and sound into the creative process.</p>
          </div>
          <div className="candle-highlight-item">
            {/* <img src="/path-to-image3.jpg" alt="Team Collaboration" className="highlight-image" /> */}
            <h3>Team Collaboration</h3>
            <p>Encouraging teamwork and cooperation among participants.</p>
          </div>
          <div className="candle-highlight-item">
            {/* <img src="/path-to-image4.jpg" alt="Finished Products" className="highlight-image" /> */}
            <h3>Finished Products</h3>
            <p>Taking home beautiful, hand-crafted candles as a symbol of achievement.</p>
          </div>
        </div>
      </section>

      {/* 4. Testimonials */}
      <section className="candle-testimonials">
        <h2>What Our Students Say</h2>
        <div className="candle-testimonial-carousel">
          {/* Testimonial 1 */}
          <div className="candle-testimonial">
            <img src="/path-to-student1.jpg" alt="Student A" className="testimonial-image" />
            <p>"Creating my own candles made me feel proud and capable!"</p>
            <span>- Student A</span>
          </div>
          {/* Testimonial 2 */}
          <div className="candle-testimonial">
            <img src="/path-to-student2.jpg" alt="Student B" className="testimonial-image" />
            <p>"I loved working with my hands and making something beautiful."</p>
            <span>- Student B</span>
          </div>
          {/* Add more testimonials as needed */}
        </div>
      </section>

      {/* 5. Get Involved Section */}
      <section className="candle-get-involved">
        <h2>Get Involved</h2>
        <p>Support our workshop by volunteering, donating, or spreading the word.</p>
        <button className="candle-cta-button">Join Us</button>
      </section>

    </div>
    </div>
  )
}

export default CandleMaking
