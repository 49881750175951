import React from 'react';
import './SewingTrainingCenter.css';

const SewingTrainingCenter = () => {
  return (
   <div>
     <div className="sewing-container"><br />
      
      {/* 1. Header */}
      <header className="sewing-header">
        <div className="sewing-header-content">
          <h1>Sewing Training Center</h1>
          <p>Empowering Lives Through Skill Development</p>
        </div>
      </header>

      {/* 2. Introduction Section */}
      <section className="sewing-intro">
        <div className="sewing-intro-content">
          <div className="sewing-intro-text">
            <h2>Welcome to Our Sewing Training Center</h2>
            <p>
              At our Sewing Training Center, we provide essential sewing skills to empower individuals with new opportunities. 
              Our comprehensive programs are designed to enhance practical skills and foster creativity.
            </p>
          </div>
          <div className="sewing-intro-image">
            <img src="https://csrbox.org/company/proj_img/1613727742xyz6.PNG" alt="Sewing Class" />
          </div>
        </div>
      </section>

      {/* 3. Program Highlights */}
      <section className="sewing-programs">
        <h2>Our Programs</h2>
        <div className="sewing-program-list">
          <div className="sewing-program-item">
            <img src="https://www.superprof.com.my/blog/wp-content/uploads/2018/01/vintage-old-fashioned-sewing-machine.jpg" alt="Basic Sewing" />
            <h3>Basic Sewing</h3>
            <p>Learn fundamental sewing techniques and get started with simple projects.</p>
          </div>
          <div className="sewing-program-item">
            <img src="https://media.assettype.com/outlookindia/2024-02/adfd6d82-541f-4615-acc3-d1a3f36f58da/unnamed___2024_02_12T223628_891.png?w=640" alt="Advanced Sewing" />
            <h3>Advanced Sewing</h3>
            <p>Enhance your skills with complex sewing techniques and pattern making.</p>
          </div>
          <div className="sewing-program-item">
            <img src="https://amylovestosew.com/wp-content/uploads/2020/03/DSC_0034.jpg" alt="Embroidery" />
            <h3>Embroidery</h3>
            <p>Explore the art of embroidery and create beautiful designs.</p>
          </div>
        </div>
      </section>

      {/* 4. Success Stories */}
      <section className="sewing-success-stories">
        <h2>Success Stories</h2>
        <div className="sewing-carousel">
          <div className="sewing-testimonial">
            <p>"This training has transformed my life. I now have the skills to start my own tailoring business."</p>
            <span>- Success Story 1</span>
          </div>
          <div className="sewing-testimonial">
            <p>"The skills I learned here have given me confidence and a new career path."</p>
            <span>- Success Story 2</span>
          </div>
          {/* Add more testimonials */}
        </div>
      </section>

      {/* 5. Our Approach */}
      <section className="sewing-approach">
        <div className="sewing-approach-content">
          <div className="sewing-approach-text">
            <h2>Our Approach</h2>
            <p>
              We provide hands-on training with a focus on practical skills and individual attention. Our expert instructors use 
              innovative teaching methods to ensure a comprehensive learning experience.
            </p>
          </div>
          <div className="sewing-approach-image">
            <img src="https://media.licdn.com/dms/image/C5112AQHyoCxjHG-zNg/article-cover_image-shrink_600_2000/0/1520082390208?e=2147483647&v=beta&t=2f8uVaBh9gP2uS4Vfg2-6xmr0H2iiP9OjqDLH0EXW2Q" alt="Our Approach" />
          </div>
        </div>
      </section>

      {/* 6. Call to Action */}
      <section className="sewing-cta">
        <h2>Get Involved</h2>
        <p>Support our mission by volunteering, donating, or spreading awareness about our programs.</p>
        <button className="sewing-cta-button">Donate Now</button>
      </section>

    </div>
   </div>
  );
}

export default SewingTrainingCenter;
