import React from 'react'
import "./Objectives.css";
import obi from "../components/img/obi.jpeg";
import object3 from "../components/img/object3.webp";

const Objectives = () => {
  return (
    
    <div>
      <div className="objectives-container"><br />
      {/* 1. Header */}
      <header className="objectives-header">
        <div className="objectives-header-content">
          <h1>Our Objectives</h1>
          <p>Driving Change Through Targeted Efforts</p>
        </div>
      </header>

      {/* 2. Objectives List */}
      <section className="objectives-list">
        <div className="objectives-grid">
          <div className="objectives-item">
            <img src={obi} alt="Objective Icon" />
            <h3>To Provide Quality Education and Skill Development</h3>
            <p className='text-start'>At Jagatguru Rambhdracharya Divyang Kalyan Sansthan (JRD), our primary objective is to deliver high-quality education and skill development programs specifically tailored for blind and orphaned children. We aim to ensure that every child has access to an inclusive learning environment where they can acquire essential academic knowledge and practical skills. Through specialized teaching methods, adaptive technologies, and personalized support, JRD strives to equip these children with the tools necessary for academic success and personal growth. This foundational education will enable them to build a strong base for future opportunities and contribute positively to their communities.</p>
          </div>
          <div className="objectives-item">
            <img src={object3} alt="Objective Icon" />
            <h3>To Empower and Promote Holistic Development and Well-Being</h3>
            <p className='text-start'> At Jagatguru Rambhdracharya Divyang Kalyan Sansthan (JRD), our objective is to empower blind and orphaned children by promoting their holistic development and well-being. We are dedicated to addressing all aspects of their growth, including physical, emotional, and social needs. Through a combination of health care, psychological support, and recreational activities, JRD strives to create a supportive environment that nurtures resilience, self-esteem, and social skills. By focusing on their comprehensive development, we aim to empower each child to lead a balanced, fulfilling life and achieve their fullest potential.</p>
          </div>
          {/* Add more objectives as needed */}
        </div>
      </section>

      {/* 3. Call to Action */}
      {/* <section className="objectives-cta">
        <h2>Get Involved</h2>
        <p>Join us in achieving these objectives. Your support makes a difference.</p>
        <button className="objectives-cta-button">Support Our Mission</button>
      </section> */}
    </div>
      {/* <h6 className='text-start ms-5'>We at Ek Pahel believe that the growth of an individual, family and that of a society can be brought about by Education, Enrichment and Empowerment of the underprivileged. Ek Pahel synergizes its efforts to build and strengthen the three pillars by focusing on the following objectives:</h6>
      <br /><br />
      <p className='text-start ms-5' style={{ color: '#6c757d', textAlign: 'start', marginLeft: '2rem' }} >• Impart quality education to underprivileged children and women</p>
      <p className='text-start ms-5' style={{ color: '#6c757d', textAlign: 'start', marginLeft: '2rem' }}>• Provide better healthcare facilities to underprivileged children and women</p>
      <p className='text-start ms-5' style={{ color: '#6c757d', textAlign: 'start', marginLeft: '2rem' }}>• Generate awareness about various social malpractices such as child labour, child trafficking, gender discrimination, domestic violence amongst the masses</p>
      <p className='text-start ms-5' style={{ color: '#6c757d', textAlign: 'start', marginLeft: '2rem' }}>• Focus on skill development of underprivileged children</p>
      <p className='text-start ms-5' style={{ color: '#6c757d', textAlign: 'start', marginLeft: '2rem' }}>• Financially and socially empower women of marginalized sections of the society</p>
      <p className='text-start ms-5' style={{ color: '#6c757d', textAlign: 'start', marginLeft: '2rem' }}>• Encourage social entrepreneurship through young people and women</p>
      <p className='text-start ms-5' style={{ color: '#6c757d', textAlign: 'start', marginLeft: '2rem' }}>• Protect and promote Indian culture and heritage</p>
      <p className='text-start ms-5' style={{ color: '#6c757d', textAlign: 'start', marginLeft: '2rem' }}>• Meet the blood requirement of blood banks by organizing blood donation camps and ensure the blood reaches to poor and needy</p>

      <br /><br /><br /><br /> */}
    </div>
  )
}

export default Objectives
