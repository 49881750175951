import React from 'react';
import './TeamMember.css';
import { useInView } from 'react-intersection-observer';
import Educate from "./img/Educate.jpg";
import Enrich from "./img/Enrichjpg.jpg";
import Empower from "./img/Empower.jpg";

const TeamMember = () => {
  const { ref: card1Ref, inView: card1InView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const { ref: card2Ref, inView: card2InView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const { ref: card3Ref, inView: card3InView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <div className='Doctor-text-TM'>
      
      {/* <h1 className='Doctor-text text-start ms-5'><b>Team Members</b></h1>
      <div className="yellow-line-TM ms-5 "></div> */}
      
      <div className="container">
        <div className="row">
          <div ref={card1Ref} className={`col-lg-4 col-md-6 mb-4 card-TM-container ${card1InView ? 'animate' : ''}`}>
            <div className="card-TM">
              <img src={Educate} className="card-img-top" alt="" />
              <div className="card-body">
                <h3><b>To Educate</b></h3>
                <p className='text-start'><b>Jagatguru Rambhdracharya Divyang Kalyan Sansthan (JRD) believes that quality education empowers children and breaks the cycle of poverty. JRD provides free, high-quality education to the underprivileged, creating a brighter future.</b></p>
              </div>
            </div>
          </div>
          <div ref={card2Ref} className={`col-lg-4 col-md-6 mb-4 card-TM-container ${card2InView ? 'animate' : ''}`}>
            <div className="card-TM">
              <img src={Enrich} className="card-img-top" alt="" />
              <div className="card-body">
                <h3><b>To Enrich</b></h3>
                <p className='text-start'><b>Jagatguru Rambhdracharya Divyang Kalyan Sansthan (JRD) believes that the foundation of happiness begins with good health. Therefore, to enrich the lives of children and women, JRD focuses on promoting health and well-being.</b></p>
              </div>
            </div>
          </div>
          
          
          <div ref={card3Ref} className={`col-lg-4 col-md-6 mb-4 card-TM-container ${card3InView ? 'animate' : ''}`}>
            <div className="card-TM">
              <img src={Empower} className="card-img-top" alt="Dr. Shweta Goswami" />
              <div className="card-body">
                <h3><b>To Empower</b></h3>
                <p className='text-start'><b>Jagatguru Rambhdracharya Divyang Kalyan Sansthan (JRD) believes that empowering vulnerable children begins with strengthening their individual and family capacities. JRD equips them to overcome challenges, paving the way for a brighter future.</b></p>
              </div>
              
            </div>
          </div>
        </div>
      </div>
      
    </div>
    
  );
};

export default TeamMember;
