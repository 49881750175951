import React from 'react';
import './FertilitySection.css';
import { useInView } from 'react-intersection-observer';

import Donate from "./img/Donate.webp"
import { Link } from "react-router-dom";


const FertilitySection = () => {
  const { ref: box1Ref, inView: box1InView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const { ref: box2Ref, inView: box2InView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <div className="container">
      <div ref={box1Ref} className={`box ${box1InView ? 'animate' : ''}`}>
        <div className="vc_column-inner vc_custom_1702970911176">
          <div className="wpb_wrapper">
            <div className="wpb_single_image wpb_content_element vc_align_center">
              <figure className="wpb_wrapper vc_figure">
                <div className="vc_single_image-wrapper vc_box_border_grey">
                  <img
                    decoding="async"
                    className="vc_single_image-img"
                    src={Donate}
                    alt="fertility"
                    title="fertility"
                    loading="lazy"
                  />
                </div>
              </figure>
            </div>
            <div className="wpb_text_column wpb_content_element">
              <div className="wpb_wrapper">
                <h4 className="entry-title" style={{ textAlign: 'center' }}><b>Quick Donate Online</b></h4>
                <p className='text-start' style={{ textAlign: 'center', color: 'grey' }}>
    Quick Donate Online is a secure and user-friendly platform designed to facilitate seamless online donations. It offers an intuitive interface and multiple payment options, making it easy for donors to contribute to their favorite causes quickly and safely.
</p>

               

              </div>
              <Link to="/" className="btn btn-warning ms-2 link-button">Donate Now</Link><br />
            </div>
          </div>
        </div>
      </div>

      <div ref={box2Ref} className={`box ${box2InView ? 'animate' : ''}`}>
        <div className="vc_column-inner vc_custom_1702970911176">
          <div className="wpb_wrapper">
            <div className="wpb_single_image wpb_content_element vc_align_center">
              <figure className="wpb_wrapper vc_figure">
                <div className="vc_single_image-wrapper vc_box_border_grey">
                
                </div>
              </figure>
            </div>
            <div className="wpb_text_column wpb_content_element">
              <div className="wpb_wrapper">
              <p className='text-start' style={{ textAlign: 'center', color: 'grey' }}>
              Using Quick Donate Online is beneficial because it streamlines the donation process, making it incredibly easy and secure for both donors and recipients. Here are a few reasons why you should consider using Quick Donate Online:

Convenience: Quick Donate Online simplifies the process of making donations, allowing you to contribute to your favorite causes from the comfort of your home, at any time.
Security: With advanced encryption technologies, your personal and financial information is kept safe, ensuring peace of mind with every transaction.
Efficiency: The platform is designed for speed and ease, reducing the time and effort required to make a donation, thereby encouraging more frequent giving.
Accessibility: Multiple payment options ensure that donors can use their preferred method to contribute, making the process more inclusive and user-friendly. <br />
Thank You 🙏
</p>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FertilitySection;
