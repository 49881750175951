import React from 'react'
import VideoPage from './VideoPage'

const VideoGallery = () => {
  return (
    <div><br />
    
      <VideoPage/>
    </div>
  )
}

export default VideoGallery
