import React from 'react'
import "./VisionMission.css";
import vision1 from "../components/img/vision1.avif";
import vision2 from "../components/img/vision2.jpg";

const VisionMission = () => {
  return (
    <div className="vision-mission-container"><br />
    {/* 1. Header */}
    <header className="vision-mission-header">
      <div className="vision-mission-header-content">
        <h1>Our Vision & Mission</h1>
        <p>Building a Brighter Future Together</p>
      </div>
    </header>

    {/* 2. Vision Section */}
    <section className="vision-section">
      <div className="vision-content">
        <h2>Our Vision</h2>
        <p className='text-start'>
        At Jagatguru Rambhdracharya Divyang Kalyan Sansthan (JRD), we envision a future where every blind and orphaned child is empowered to achieve their full potential. We are dedicated to providing these children with quality education and essential skills, laying the foundation for a brighter, more promising future. By offering tailored support and opportunities, we strive to ensure that each child can overcome challenges, embrace their abilities, and build a successful and fulfilling life.




<br /><br />

        At Jagatguru Rambhdracharya Divyang Kalyan Sansthan (JRD), we aspire to create a future where every blind and orphaned child is not only educated and skilled but also inspired to rise above their circumstances and achieve greatness.
        </p>
      </div>
      <div className="vision-image">
        <img src={vision1} alt="Vision" />
      </div>
    </section>

    {/* 3. Mission Section */}
    <section className="mission-section">
      <div className="mission-content">
        <h2>Our Mission</h2>
        <p className='text-start'>
        At Jagatguru Rambhdracharya Divyang Kalyan Sansthan (JRD), our mission is to provide blind and orphaned children with the highest quality education and skill development opportunities. We are dedicated to nurturing their growth through personalized support, comprehensive training, and resources tailored to their unique needs. By empowering these children with the tools and confidence to succeed, we aim to transform their lives and enable them to reach their full potential, contributing to a more inclusive and equitable society.
<br /><br />
        Beyond education, we strive to inspire confidence, resilience, and a sense of purpose in each child. By fostering a nurturing environment, we aim to help them overcome challenges and realize their full potential. Our commitment is to equip these children with the tools and opportunities necessary to build a brighter future, transforming their lives and enabling them to make meaningful contributions to their communities.
        </p>
      </div>
      <div className="mission-image">
        <img src={vision2} alt="Mission" />
      </div>
    </section>

    {/* 4. Call to Action
    <section className="vision-mission-cta">
      <h2>Join Us in Our Journey</h2>
      <p>Support our vision and mission by getting involved today.</p>
      <button className="vision-mission-cta-button">Get Involved</button>
    </section> */}
  </div>
  )
}

export default VisionMission
